$background: #f2f2f2;
$light-blue: #426A8C;
$dark-blue: #1e3040;
$dark-orange: #f25244;
$light-orange: #f27166;
$purple: #8c79b1;
$event-card-height: 100px;

body {
  background-color: $background;
  color: $dark-blue;
  overscroll-behavior: none;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: $dark-blue;
}

a {
  color: #66a3d9;
}

a:hover {
  color: #487399;
}

.footer {
  background-color: $background;
}

.navbar-search-light .input-group {
  background-color: $background;
}

.bg-mine {
  background-color: $dark-blue;
}

.text-mine {
  color: $dark-blue;
}

.bg-light-orange {
  background-color: $light-orange !important;
}

.btn-neutral, .btn-link {
  color: $dark-blue;
}

.btn-link:hover {
  color: $light-blue;
}

.btn-primary {
  background-color: $dark-blue;
  border-color: $dark-blue;
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #aaa;
  border-color: #aaa;
  cursor: not-allowed;
}

.btn-primary:hover:not(:disabled), .btn-primary:active:not(:disabled), .addeventatc:hover:not(:disabled) {
  background-color: $light-blue !important;
  border-color: $light-blue !important;
}

.btn-warning {
  background-color: $light-orange;
  border-color: $light-orange;
}

.btn-warning:hover, .btn-warning:active {
  background-color: $dark-orange !important;
  border-color: $dark-orange !important;
}

.fill-default {
  fill: $dark-blue;
}

.bg-default {
  background-color: $dark-blue !important;
}

.bg-secondary {
  background-color: $background !important;
}

.bg-danger {
  background-color: $dark-orange !important;
}

.bg-light-danger {
  background-color: $light-orange !important;
}

.alert-danger {
  background-color: $dark-orange;
  border-color: $dark-orange;
  font-size: 1em;
}

.alert-success {
  background-color: $dark-blue;
  border-color: $dark-blue !important;
  font-size: 1em;
}

.custom-toggle-success input:checked + .custom-toggle-slider {
  border-color: $dark-blue;
}

.custom-toggle-success input:checked + .custom-toggle-slider::before {
  background: $dark-blue;
}

.custom-toggle-success input:checked + .custom-toggle-slider::after {
  color: $dark-blue;
}

#onesignal-popover-container #onesignal-popover-dialog .popover-footer .popover-button.primary {
  background-color: $dark-blue !important;
}

#onesignal-popover-container #onesignal-popover-dialog .popover-footer .popover-button.secondary {
  color: $dark-blue !important;
}

.addeventatc.add-to-calendar-btn {
  background-color: $dark-blue;
  border-color: $dark-blue;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff !important;
  font-size: 0.875rem !important;
  padding: 13px 0;
}

.cursor-displayed {
  cursor: pointer;
}

.close-button-prompt {
  font-size: 3rem;
  color: inherit;
  
}

.login-button, .subscription-button, .filter-button {
  margin-bottom: 1rem !important;
  width: 100%;
}

.filter-button {
  margin-top: 0 !important;
}

.filter-buttons-container {
  margin-top: 0.75rem;
}

.create-account-button, .cancel-subscription-button {
  margin: 0 0 0.8rem 0 !important;
  width: 100%;
}

.img-event {
  border: 1px solid;
}

.btn-expand, .btn-back {
  cursor: pointer;
  font-size: 1.25rem;
}

.btn-back {
  position: absolute;
}

.btn-install-prompt {
  background-color: $dark-blue;
  color: white;
}

.alert-mine {
  color: #212529;
  border-color: white;
  background-color: white;
  box-shadow: 0 0 0.5rem 0 rgba(136, 152, 170, 0.25);
}

.avatar {
  color: #000;
  background-color: #fff;
}

.background-top {
  position: fixed;
  height: 350px;
  width: 100%;
  background-color: #43CECB;
}

.g-sidenav-show .sidenav.navbar-vertical {
  background-image: url("/img/ciip/ciip-logo.png");
  background-size: contain;
  background-position: 0% 95%;
  background-repeat: no-repeat;
}

.card {
  box-shadow: 0 0 0.5rem 0 rgba(136, 152, 170, 0.25);
}

.mini-search {
  align-items: flex-start;
}

.mini-search-group-form-group {
  width: 100%;
}

.event, .holiday {
  min-width: 50%;
}

.event-card, .holiday-card {
  border-radius: 0;
  cursor: pointer;
  height: $event-card-height;
}

.event-squared-div, .holiday-squared-div {
  height: $event-card-height;
  width: $event-card-height;
}

.event:nth-child(even) .event-date, .holiday:nth-child(even) .holiday-date {
  background-color: $light-orange;
}

.event:nth-child(odd) .event-date, .holiday:nth-child(odd) .holiday-date {
  background-color: $dark-orange;
}

.event-date, .holiday-date {
  color: white;
  padding-top: 0.7rem;

  .event-date-day, .holiday-date-day {
    font-size: 2rem;
  }

  .event-date-month, .holiday-date-month {
    font-size: 1.25rem;
    line-height: 1.25;
  }
}

.event-image, .holiday-image {
  background-position: left center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  border-right: 1px solid gray;
}

.event-content, .holiday-content {
  min-width: 0;
  
  .event-title, .event-address, .holiday-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .icon-event {
    padding: 0 6px 0 0 !important;

    i {
      font-size: 1rem !important;
    }
  }

  .icon-holiday {
    padding: 0 6px 0 0 !important;

    i {
      font-size: 1rem !important;
    }
  }
}

.event-details-image, .holiday-details-image {
  max-height: 100px;
  max-width: 100%;
}

.filter-date-label {
  font-size: 0.875rem;
}

.event-icon-container {
  display: inherit;
}

.btn-event-opinion {
  margin-right: 0 !important;
}

.addeventatc {
  margin-top: 1em;
  text-align: center;
}

.addeventatc_icon {
  display: none;
}

.fc-toolbar {
  display: flex !important;
}

.fc-button-primary {
  background-color: $dark-blue !important;
  border-color: $dark-blue !important;
}

.fc-button-active {
  background-color: black !important;
}

.fc-prev-button, .fc-next-button {
  font-size: 0.95em !important;
}

.fc-icon {
  height: 1em !important;
  line-height: 1 !important;
  width: 1em !important;
}

.fc-icon:hover {
  color: white;
}

.custom-control-label::before {
  border: 1px solid $dark-blue;
}

.custom-control-input:focus ~ .custom-control-label::before {
  border-color: $dark-blue !important;
}

.custom-control-input:active ~ .custom-control-label::before {
  background-color: $dark-blue !important;
  border-color: $dark-blue !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: $dark-blue;
  border-color: $dark-blue !important;
}

.fc th {
  padding: .75rem 1rem !important;
}

@media only screen and (max-width: 768px) {
  .event-squared-div, .holiday-squared-div {
    width: 70px;
  }

  .event-date, .holiday-date {
    .event-date-day, .holiday-date-day {
      font-size: 1.5rem;
    }
  
    .event-date-month, .holiday-date-month {
      font-size: 0.94rem;
    }

    .event-date-year, .holiday-date-year {
      font-size: 0.75rem;
    }
  }

  .event-image, .holiday-image {
    display: none;
  }

  .calendar-container {
    padding: 0;
  }
  
  .calendar-card-container {
    padding: 1.5rem 0;
  }

  .fc-event-container {
    .fc-time {
      display: none;
    }

    .fc-title {
      font-size: 0.8em;
      font-weight: normal;
      padding: 0.25em 0.15em;
      text-overflow: unset;
    }
  }

  .dashboard-content {
    padding-top: 69px !important;
  }

  .fc-toolbar {
    display: block !important;
    margin-bottom: 0.5em;
    text-align: center;

    .fc-left, .fc-center {
      display: inline-block;
    }

    .fc-center {
      margin-left: 0.75em;
    }

    .fc-right {
      font-size: 0.75em;
      margin: 0.5em 0;
    }
  }

  .fc-button {
    font-size: 0.75em !important;
  }

  .fc th {
    padding: 0 !important;
  }
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  width: -moz-available;          /* For Mozzila */
  width: -webkit-fill-available;  /* For Chrome */
  width: stretch;                 /* Unprefixed */
  z-index: 999;
}

.dashboard-content {
  padding-top: 77px;
}

.fc-event-container {
  cursor: pointer;
}

.fc-time {
  color: #fff;
}

.calendar-event-card-body {
  background-color: white;
  padding-left: 0;
  padding-right: 0;
}

.close-calendar-event-button {
  margin: 0;
}

.title-calendar {
  float: left;
}

.show-holidays-checkbox {
  float: right;
  margin-top: 5px;
}

.custom-checkbox .custom-control-input ~ .custom-control-label::after, .custom-checkbox .custom-control-input ~ .custom-control-label::before {
  left: -1.5rem;
}

.fc-button {
  border: 1px solid transparent !important;
}

.fc-unthemed td.fc-today {
  background: #fcf8e3 !important;
}

.fc .fc-row .fc-content-skeleton table, .fc .fc-row .fc-content-skeleton td, .fc .fc-row .fc-mirror-skeleton td {
  background: none !important;
}